<script>
import axios from "axios";
export default {
  methods: {
    async executeRequest(method, route, payload = null) {
      this.$store.dispatch("activeLoader", true);
      try {
        const response = await this.$http[method](route, payload);
        if (!response.data.success) {
          this.$store.dispatch("showMessage", {
            show: true,
            color: "warning darken-1",
            text: response.data.warning,
          });
          this.$store.dispatch("activeLoader", false);
          return;
        }

        this.$store.dispatch("showMessage", {
          show: true,
          color: "success darken-1",
          text: "Operação realizada com sucesso!",
        });
        this.$store.dispatch("activeLoader", false);

        return response.data;
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "error darken-1",
          text: error.message,
        });
        this.$store.dispatch("activeLoader", false);
      }
    },
    convertObjectKeysToUppercase(objectData) {
      for (const [key, value] of Object.entries(objectData)) {
        objectData[key] = value ? value.toUpperCase().trim() : value;
      }

      return objectData;
    },
    makeTimeTextBr(time) {
      if (time) {
        const [hour, min] = time.split(":");
        return `${hour}h - ${min}min`;
      }
      return "";
    },
    getDateExtenso(tripDate) {
      var data = tripDate + "T12:00:00Z";
      data = new Date(data);
      var day = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ][data.getDay()];
      var date = data.getDate();
      var month = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ][data.getMonth()];
      var year = data.getFullYear();
      return `${day}, ${date} de ${month} de ${year}`;
    },
    valorBrasileiro(v) {
      return v
        ? v.toLocaleString("pt-br", { minimumFractionDigits: 2 })
        : "0,00";
    },
    valorAmericano(v) {
      return v.toLocaleString("en", { minimumFractionDigits: 2 });
    },
    getDayOfWeek(date) {
      const dayOfWeek = new Date(date).getDay() + 1;
      const week = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
      return week[dayOfWeek];
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    convertDateTimeNew(v) {
      if (v) {
        v = v.replace("-", "").replace("-", "");
        let year = v.substring(0, 4);
        let month = v.substring(4, 6);
        let day = v.substring(6, 8);
        let hour = v.substring(9, 11);
        let min = v.substring(12, 14);
        return `${day}/${month}/${year} ${hour}:${min}`;
      } else {
        return false;
      }
    },
    formatDateToSendExcessBaggage(date) {
      if (date) {
        date = date.replace("-", "").replace("-", "");
        let year = date.substring(0, 4);
        let month = date.substring(4, 6);
        let day = date.substring(6, 8);
        let hour = date.substring(9, 11);
        let min = date.substring(12, 14);
        return `${year}-${month}-${day}T${hour}:${min}:00-03:00`;
      } else {
        return false;
      }
    },
    // FORMATAR DATA NOS INPUTS DE SELECAO ****************************************************************************************
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // FORMATAR DATA NOS INPUTS DE SELECAO ****************************************************************************************
    // CONVERTER DATA NOS INPUTS DE SELECAO ****************************************************************************************
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // CONVERTER DATA NOS INPUTS DE SELECAO ****************************************************************************************
    convertCurrent(val) {
      if (val > 0) {
        return Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(val);
      }
      return Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(0);
    },
    // INICIO DO PROCEDIMENTO DO BUSCA CEP *************************************************************
    // São passados parametros iguais aos valores contidos nos campos do formulario do cliente **********************************************************
    selectCobranca(cobranca) {
      const cobrancas = [
        { id: 1, desc: "PAGO" },
        { id: 2, desc: "A PAGAR" },
        { id: 3, desc: "CONTRATO" },
      ];

      const result = cobrancas.find((cob) => cob.id == cobranca);

      return result ? result.desc : "PAGO";
    },
    setExpedidorDesc(expedidor) {
      const items = ["AGÊNCIA", "MOTORISTA"];

      return items[expedidor];
    },
    async getCep(objectReplace) {
      if (objectReplace["cep"].length == 10) {
        this.loading = true;
        objectReplace["cep"] = objectReplace["cep"].replace("-", "");
        objectReplace["cep"] = objectReplace["cep"].replace(".", "");
        const response = await axios.get(
          "https://viacep.com.br/ws/" + objectReplace["cep"] + "/json/"
        );
        objectReplace["logradouro"] = response.data.logradouro;
        objectReplace["bairro"] = response.data.bairro;
        objectReplace["cidade"] = response.data.localidade;
        objectReplace["uf"] = response.data.uf;
      }

      return objectReplace;
    },
    // FINAL DO PROCEDIMENTO DO BUSCA CEP *************************************************************

    titleCase(str) {
      const splitStr = str.toLowerCase().split(/(\([A-Z]{2}\))/i);

      const newSplitStr = splitStr.map((item) => {
        if (/\([A-Z]{2}\)/i.test(item)) {
          return item.toUpperCase();
        }
        return item;
      });

      return newSplitStr.join("");
    },
    cityCase(str) {
      const splitStr = str.toLowerCase().split("-");

      splitStr[1] = splitStr[1].toUpperCase();

      return splitStr.join(" - ");
    },
  },
  capitalize(text) {
    return (text && text[0].toUpperCase() + text.slice(1)) || "";
  },
};
</script>

<template>
  <v-container class="mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" color="primary" @click="$router.back()"
        >mdi-arrow-left</v-icon
      >
      <h3 class="title text-uppercase" style="color: #2054af">
        Gerenciar Encomendas
      </h3>
    </v-subheader>
    <v-card flat>
      <v-card-title>
        <v-row dense class="text-center">
          <v-col cols="12" sm="12" md="2">
            <DatePicker
              :label="'Data Inicial'"
              @dateValue="[(filter.dateStart = $event)]"
            />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <DatePicker
              :label="'Data Final'"
              @dateValue="[(filter.dateEnd = $event)]"
            />
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Pesquisa rápida pelos dados filtrados"
              single-line
              hide-details
              flat
              :disabled="items.length == 0 ? true : false"
            ></v-text-field>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="12" md="3">
            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="searchData"
                  color="green"
                  depressed
                  dark
                  v-on="on"
                  class="mb-2 mt-2"
                >
                  <v-icon>mdi-magnify</v-icon> BUSCAR
                </v-btn>
              </template>
              <span>Buscar pelos termos do filtro</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  depressed
                  dark
                  v-on="on"
                  class="mb-2 ml-2 mt-2"
                  to="/encomendas/new"
                >
                  <v-icon>mdi-plus</v-icon> Novo
                </v-btn>
              </template>
              <span>Nova Encomenda</span>
            </v-tooltip>
            <!-- <v-tooltip bottom color="blue-grey darken-2">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="blue-grey darken-2"

                  dark
                  v-on="on"
                  class="mb-2 ml-2 mt-2"
                  @click="initialize"
                >
                  <v-icon class="mr-1">mdi-update</v-icon>
                </v-btn>
              </template>
              <span>Recarregar</span>
            </v-tooltip> -->
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" text>
                <v-icon>mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item>
                <v-tooltip bottom color="green darken-3">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      v-on="on"
                      depressed
                      text
                      block
                      color="green darken-3"
                      :disabled="
                        item.status == 'ENVIADO' || item.status == 'ENTREGUE'
                          ? true
                          : false
                      "
                      @click="
                        sendItem({
                          id_encomenda: item.id_encomenda,
                          id_operador: $store.getters.getOperador,
                          id_agencia: item.id_agencia,
                          status: 'ENVIADO',
                        })
                      "
                      >Enviar</v-btn
                    >
                  </template>
                  <span>Enviar</span>
                </v-tooltip>
              </v-list-item>

              <v-list-item>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="item.status != 'RECEBIDO' ? true : false"
                      @click="
                        $router.push(`/encomendas/edit/${item.id_encomenda}`)
                      "
                      small
                      v-on="on"
                      block
                      depressed
                      text
                      color="primary"
                      >Editar</v-btn
                    >
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </v-list-item>

              <v-list-item>
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      v-on="on"
                      depressed
                      text
                      block
                      color="red"
                      :disabled="item.status != 'RECEBIDO' ? true : false"
                      @click="cancelItem(item.id_encomenda)"
                      >Cancelar</v-btn
                    >
                  </template>
                  <span>Cancelar Encomenda</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="[printItem(item)]"
                      :disabled="item.cancelada == 0 ? false : true"
                      small
                      v-on="on"
                      depressed
                      text
                      block
                      color="black"
                      >Imp. A4
                    </v-btn>
                  </template>
                  <span>Imprimir na impressora A4</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="[printMiniPrinterItem(item)]"
                      :disabled="item.cancelada == 0 ? false : true"
                      small
                      v-on="on"
                      depressed
                      text
                      block
                      color="black"
                      >Imp. Termica</v-btn
                    >
                  </template>
                  <span>Imprimir na impressora térmica</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.cobranca="{ item }">
          {{
            item.cobranca == 1
              ? "PAGO"
              : item.cobranca == 2
              ? "À PAGAR"
              : "CONTRATO"
          }}
        </template>
        <template v-slot:item.valor_total="{ item }">
          {{ convertCurrent(item.valor_total) }}
        </template>
        <template v-slot:item.cancelada="{ item }">
          {{ item.cancelada == 1 ? "SIM" : "NÃO" }}
        </template>
      </v-data-table>
    </v-card>
    <OrderPrint style="display: none" :dados="dadosRecuperados" />
  </v-container>
</template>

<script>
import OrderPrint from "@/components/printers/bilhets/Packpages.vue";
import DatePicker from "@/components/Calendars.vue";
import Helpers from "@/mixins";
export default {
  mixins: [Helpers],
  components: {
    OrderPrint,
    DatePicker,
  },
  data: () => ({
    isShow: false,
    encomenda: {},
    dialog: false,
    modalShow: true,
    search: "",
    filter: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    dadosRecuperados: [],
    headers: [
      { text: "Cód.Barras", value: "cod_barras", sortable: false },
      { text: "Ag.Origem", value: "origem", sortable: false },
      { text: "Ag.Destino", value: "destino", sortable: false },
      { text: "Remetente", value: "remetente", sortable: false },
      { text: "Destinatario", value: "destinatario", sortable: false },
      { text: "Valor", value: "valor_total", sortable: false },
      { text: "Cobrança", value: "cobranca", sortable: false },
      { text: "Forma de Pagamento", value: "formapagamento", sortable: false },
      { text: "Cancelado", value: "cancelada", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
    id_encomenda: 0,
  }),
  created() {
    this.Helpers;
    this.initialize();
  },
  methods: {
    showModal() {
      return !this.modalShow;
    },
    printItem(item) {
      this.dadosRecuperados = item;
      setTimeout(() => {
        this.$htmlToPaper("imprimir");
      }, 200);
    },
    async printMiniPrinterItem(item) {
      this.$http.post(`/impressoes/encomendas`, { cupom: item }).then(() => {
        this.$store.dispatch("activeLoader", false);
      });
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(
          `/encomendas/por-agencia/${this.$store.getters.getOpAgency}`,
          this.filter
        )
        .then((r) => {
          this.items = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
    sendItem(dados) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/encomendas/parada`, dados)
        .then(() => {
          this.searchData();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    searchData() {
      this.$store.dispatch("activeLoader", true);
      this.items = [];
      this.$http
        .post(
          `/encomendas/por-agencia/${this.$store.getters.getOpAgency}`,
          this.filter
        )
        .then((r) => {
          this.items = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
    editar(id) {
      this.$router.push(`/encomendas/editar/${id}`);
    },
    cancelItem(id) {
      let c = confirm("Deseja cancelar a encomenda?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .post(`/encomendas/cancelar/${id}`, {
            id_encomenda: id,
            id_operador: this.$store.getters.getOperador,
            id_agencia: this.$store.getters.getOpAgency,
            status: "CANCELADA",
          })
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: `${r.data.result ? "success" : "error"} darken-1`,
              text: r.data.result ? r.data.result : r.data.error,
            });
            this.initialize();
          });
      }
    },
  },
};
</script>

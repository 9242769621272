<template>
  <div class="container" style="width: 300px" id="imprimir">
    <div class="content">
      <header
        style="flex-direction: column"
        class="d-flex align-items-md-center justify-content-between"
      >
        <img
          src="@/assets/logoBlue.png"
          alt="[logo]"
          style="width: 400px; padding: 20px"
        />
        <div>
          <h4 class="text-center">
            <barcode :value="dados.cod_barras" height="30"></barcode>
          </h4>
        </div>
        <div>
          <p>
            {{ dados.origem }},
            {{
              convertDateTimeNew(
                new Date(
                  Date.now() - new Date().getTimezoneOffset() * 60000
                ).toISOString()
              )
            }}
          </p>
        </div>
      </header>
      <br />
      <div style="margin-bottom: 5px">
        <table class="table table-sm table-bordered" style="width: 100%">
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left">Remetente</th>
              <th scope="col" colspan="6" class="text-left">Fone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="6">{{ dados.remetente }}</td>
              <td colspan="6">{{ dados.fone_remetente }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left">Destinatário</th>
              <th scope="col" colspan="6" class="text-left">Fone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="6">{{ dados.destinatario }}</td>
              <td colspan="6">{{ dados.fone_destinatario }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left">Origem</th>
              <th scope="col" colspan="6" class="text-left">Destino</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="6">{{ dados.origem }}</td>
              <td colspan="6">{{ dados.destino }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="4" class="text-left">
                Ass. do Bilheteiro
              </th>
              <th scope="col" colspan="2" class="text-left">Natureza</th>
              <!-- <th scope="col" colspan="2" class="text-left">Valor R$</th> -->
              <th scope="col" colspan="2" class="text-left">Volumes</th>
              <th scope="col" colspan="2" class="text-left">Total R$</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4"></td>
              <td colspan="2">{{ dados.natureza }}</td>
              <!-- <td colspan="2">{{ dados.valor }}</td> -->
              <td colspan="2">{{ dados.quant_volumes }}</td>
              <td colspan="2">{{ dados.valor_total }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-sm table-bordered" style="width: 100%">
          <thead>
            <tr>
              <th scope="col" colspan="4" class="text-left">Motorista</th>
              <th scope="col" colspan="4" class="text-left">Carro</th>
              <!-- <th scope="col" colspan="4" class="text-left">Valor R$</th> -->
              <th scope="col" colspan="4" class="text-left">Total R$</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4">{{ dados.motorista }}</td>
              <td colspan="4">{{ dados.carro }}</td>
              <!-- <td colspan="4">{{ dados.valor }}</td> -->
              <td colspan="4">{{ dados.valor_total }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left">Ass. Recebedor</th>
              <th scope="col" colspan="4" class="text-left">RG</th>
              <th scope="col" colspan="4" class="text-left">
                Data de Retirada
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="6">
                <br />
              </td>
              <td colspan="4">
                <br />
              </td>
              <td colspan="4">
                <br />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="8" class="text-left">Origem</th>
              <th scope="col" colspan="8" class="text-left">Destino</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="8">{{ dados.origem }}</td>
              <td colspan="8">{{ dados.destino }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col" colspan="9" class="text-left">Observações</th>
              <th scope="col" colspan="8" class="text-left">
                Status Pagamento
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="9">
                <br />
              </td>
              <td colspan="8">
                {{
                  dados.cobranca == 1
                    ? "ENCOMENDA PAGA"
                    : dados.cobranca == 2
                    ? "ENCOMENDA À PAGAR"
                    : "CONTRATO"
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="position: relative; top: -10px">
          <div style="font-size: 0.8em">TRANSBRAZ VIAGENS E TURISMO</div>
          <div style="font-size: 0.8em">
            RUA MARECHAL RONDON, 151 - CENTRO, CEP: 56700-000 - SÃO JOSÉ DO
            EGITO-PE
          </div>
          <div style="font-size: 0.8em">
            TELEFONE: (87) 3844-1031 / TEL. (81) 3731-5575 / SAC 0800 2006 /
            Defic. Audit. 0800 880 2007
          </div>
          <div style="font-size: 0.8em">
            www.transbraz.com.br / AG.SJE@transbraz.com.br
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import mixin from "@/mixins";
export default {
  mixins: [mixin],
  components: {
    barcode: VueBarcode,
  },
  props: ["dados", "data", "saidas", "entradas", "totais"],
  data() {
    return {
      agencia: null,
    };
  },
  mounted() {
    this.mixin;
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-9"},[_c('v-subheader',{staticClass:"title mb-4 text-uppercase pa-0"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("mdi-arrow-left")]),_c('h3',{staticClass:"title text-uppercase",staticStyle:{"color":"#2054af"}},[_vm._v(" Gerenciar Encomendas ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":'Data Inicial'},on:{"dateValue":function($event){[(_vm.filter.dateStart = $event)]}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":'Data Final'},on:{"dateValue":function($event){[(_vm.filter.dateEnd = $event)]}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Pesquisa rápida pelos dados filtrados","single-line":"","hide-details":"","flat":"","disabled":_vm.items.length == 0 ? true : false},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mt-2",attrs:{"color":"green","depressed":"","dark":""},on:{"click":_vm.searchData}},on),[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" BUSCAR ")],1)]}}])},[_c('span',[_vm._v("Buscar pelos termos do filtro")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 ml-2 mt-2",attrs:{"color":"primary","depressed":"","dark":"","to":"/encomendas/new"}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Novo ")],1)]}}])},[_c('span',[_vm._v("Nova Encomenda")])])],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","text":"","block":"","color":"green darken-3","disabled":item.status == 'ENVIADO' || item.status == 'ENTREGUE'
                        ? true
                        : false},on:{"click":function($event){return _vm.sendItem({
                        id_encomenda: item.id_encomenda,
                        id_operador: _vm.$store.getters.getOperador,
                        id_agencia: item.id_agencia,
                        status: 'ENVIADO',
                      })}}},on),[_vm._v("Enviar")])]}}],null,true)},[_c('span',[_vm._v("Enviar")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.status != 'RECEBIDO' ? true : false,"small":"","block":"","depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(("/encomendas/edit/" + (item.id_encomenda)))}}},on),[_vm._v("Editar")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","text":"","block":"","color":"red","disabled":item.status != 'RECEBIDO' ? true : false},on:{"click":function($event){return _vm.cancelItem(item.id_encomenda)}}},on),[_vm._v("Cancelar")])]}}],null,true)},[_c('span',[_vm._v("Cancelar Encomenda")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.cancelada == 0 ? false : true,"small":"","depressed":"","text":"","block":"","color":"black"},on:{"click":function($event){[_vm.printItem(item)]}}},on),[_vm._v("Imp. A4 ")])]}}],null,true)},[_c('span',[_vm._v("Imprimir na impressora A4")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.cancelada == 0 ? false : true,"small":"","depressed":"","text":"","block":"","color":"black"},on:{"click":function($event){[_vm.printMiniPrinterItem(item)]}}},on),[_vm._v("Imp. Termica")])]}}],null,true)},[_c('span',[_vm._v("Imprimir na impressora térmica")])])],1)],1)],1)]}},{key:"item.cobranca",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.cobranca == 1 ? "PAGO" : item.cobranca == 2 ? "À PAGAR" : "CONTRATO")+" ")]}},{key:"item.valor_total",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.valor_total))+" ")]}},{key:"item.cancelada",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.cancelada == 1 ? "SIM" : "NÃO")+" ")]}}])})],1),_c('OrderPrint',{staticStyle:{"display":"none"},attrs:{"dados":_vm.dadosRecuperados}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }